import * as React from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import { clsx } from 'clsx';
import styles from './price-breakdown.strict-module.css';

export function PriceBreakdownLoader({ products = 0 }: { products?: number }): React.JSX.Element {
  return (
    <div className={styles.container} data-test-price-breakdown-loader>
      {Array(products)
        .fill(null)
        .map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key -- static array
          <React.Fragment key={index}>
            <div className="mb-8">
              <SkeletonLoader.Line width="144px" />
            </div>

            <div className="mb-8">
              <SkeletonLoader.Line width="72px" />
            </div>
          </React.Fragment>
        ))}

      <div className={clsx(products > 0 && styles['title-margin'])}>
        <div className="mb-16">
          <SkeletonLoader.Line width="72px" />
        </div>
        <SkeletonLoader.Line width="144px" />
      </div>

      <div className={clsx(products > 0 && styles['title-margin'], styles['loader-padding'])}>
        <SkeletonLoader.Line width="96px" />
      </div>
    </div>
  );
}

export interface PriceBreakdownProps {
  products: { title: string; price: string }[];
  total: string;
  billing: string;
  price: string;
  children?: React.ReactNode;
}

export function PriceBreakdown({
  products,
  total,
  billing,
  price,
  children,
}: PriceBreakdownProps): React.JSX.Element {
  return (
    <div className={clsx(styles.wrapper)}>
      <div className={styles.products}>
        {products.map((product, index) => (
          <div className={styles.product} key={product.title}>
            <div className="body-1" data-test-price-breakdown-item={index}>
              {product.title}
            </div>
            <div className="body-1" data-test-price-breakdown-item-price={index}>
              {product.price}
            </div>
          </div>
        ))}
      </div>
      {children}
      <div className={styles.container}>
        <div>
          <div className="title-4" data-test-total-title>
            {total}
          </div>
          <div className={clsx(styles['color-secondary'], 'caption')} data-test-total-billing>
            {billing}
          </div>
        </div>
        <div className="title-2" data-test-total-price>
          {price}
        </div>
      </div>
    </div>
  );
}

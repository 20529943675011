import type { ReactElement } from 'react';
import type { ModalOverlayProps as AriaModalProps } from 'react-aria-components';
import { Modal } from 'react-aria-components';
import cx from 'clsx';
import { CloseIcon } from '../../assets/icons/close-icon';
import { Button } from '../button';
import styles from './popup.strict-module.css';

export interface PopupProps extends AriaModalProps {
  title: string;
  subtitle: string;
  type: 'confirm' | 'destructive' | 'info' | 'promotional';
  illustration?: React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmText?: string;
  cancelText?: string;
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;
  closeClassName?: string;
  children: React.ReactNode;
}

export function Popup({
  title,
  subtitle,
  type,
  illustration,
  onConfirm,
  onOpenChange,
  onCancel = () => {
    onOpenChange?.(false);
  },
  confirmText,
  cancelText,
  children,
  className,
  headerClassName,
  bodyClassName,
  footerClassName,
  closeClassName,
  ...props
}: PopupProps): ReactElement {
  return (
    <Modal {...props} className={cx(styles.wrapper, className)}>
      <Button
        aria-label="Close"
        className={cx(styles.close, closeClassName)}
        iconOnly
        onPress={onCancel}
        type="button"
        variant="tertiary"
      >
        <CloseIcon aria-hidden />
      </Button>
      {illustration ? (
        <div className={cx(type === 'promotional' && styles.promotionalIllustration)}>
          {illustration}
        </div>
      ) : null}
      <div className={cx(styles.header, headerClassName)}>
        <h1 className={cx(styles.title, 'title-2')}>{title}</h1>
        <h2 className={cx(styles.subtitle)}>{subtitle}</h2>
      </div>
      {Boolean(children) && <div className={cx(styles.body, bodyClassName)}>{children}</div>}
      {type !== 'info' && (
        <div className={cx(styles.footer, footerClassName)}>
          <Button onPress={onCancel} variant="secondary">
            {cancelText}
          </Button>
          <Button onPress={onConfirm} variant={type === 'destructive' ? 'danger' : 'primary'}>
            {confirmText}
          </Button>
        </div>
      )}
    </Modal>
  );
}
